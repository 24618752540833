<template>
  <p>Page not found All</p>
</template>


<script>
export default {
  name: 'PageNotFoundAll'
}
</script>
